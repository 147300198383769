import Currency from "./Currency";
import ServiceInterface from "./Services/ServiceInterface";

export enum EventType {
  Wedding = "Wedding",
  Sports = "Sports",
  Other = "Other",
}

export enum LiquorCoverage {
  None = "None",
  HostLiquor = "Host Liquor",
  LiquorLiability = "Liquor Liability",
}

export interface GllOption {
  occurrence: number;
  aggregate: number;
}

export interface InputOptions {
  eventType: EventType;
  gll: GllOption;
  drp: number;
  medical: number;
  liquor: LiquorCoverage;
  endorsement: number;
  nonOwned: number;
}

export interface CarrierOptions {
  eventType: boolean;
  gll: boolean;
  drp: boolean;
  medical: boolean;
  liquor: boolean;
  endorsement: boolean;
  nonOwned: boolean;
}

export interface Carrier {
  carrierCode: string;
  service: ServiceInterface;
  options: {
    eventType: EventType[];
    gll: GllOption[];
    drp: number[];
    medical: number[];
    liquor: LiquorCoverage[];
    endorsement: number[];
    nonOwned: number[];
  };
  logo: string;
  url: string;
}

export interface CarrierResult {
  carrierCode: string;
  options: CarrierOptions;
  values: InputOptions;
  amount?: Currency;
  logo?: string;
  url?: string;
}
